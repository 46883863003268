<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col lg:flex-row">
            <div class="w-full">
              <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-0">
                  <vs-input class="w-full mt-0" label="Nome" v-model="doctor.name" data-vv-as="Nome" v-validate.initial="'required|alpha_spaces'" name="name" />
                  <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
                <div class="vx-col md:w-1/3 w-full mt-0">
                  <vs-input class="w-full mt-0" label="CRM" v-model="doctor.crm" />
                </div>
                <div class="vx-col md:w-1/3 w-full mt-0">
                  <label class="vs-input--label">Tipo</label>
                  <v-select v-model="doctor.class" :reduce="option => option.value"
                    :clearable="false" :options="[
                      {value: 'Geral', label: 'Geral'},
                      {value: 'Psicólogo', label: 'Psicólogo'}
                    ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Desculpe, nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="flex items-end mt-2">
                <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
                <span class="leading-none font-medium">Endereço</span>
              </div>
              <hr>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="doctor.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <label class="vs-input--label">Logradouro</label>
                  <v-select v-model="doctor.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Desculpe, nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Endereço" v-model="doctor.address.street" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Número" v-model="doctor.address.number" type="number" placeholder="S/N" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="doctor.address.complement" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="doctor.address.neighborhood" />
        </div>
        <div class="vx-col md:w-1/4_ w-full mt-2">
          <label class="vs-input--label">Cidade</label>
          <v-select v-model="doctor.address.city_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select v-model="doctor.address.state_id" :reduce="option => option.value" @input="fillCity(doctor.address.state_id), doctor.address.city_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">País</label>
          <v-select v-model="doctor.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Contato</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in doctor.phones" :key="i">
          <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
        </div>
        <div class="vx-col md:w-2/5 w-full">
          <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" v-model="doctor.email" type="email" v-validate.initial="{ email: true }" name="email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full">
          <vs-input class="w-full mt-2" label="Observações" v-model="doctor.observation" />
        </div>
      </div>


      <div class="flex items-end mt-2">
        <feather-icon icon="InfoIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">CACs</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-2">
          <v-select class="vue_select_drop_size_200" multiple v-model="doctor.cacs" clearable :reduce="option => option.value"
            :options="cacs" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import moduleStates from '@/store/state/moduleStates.js'
import moduleCac from '@/store/cac/moduleCac.js'

export default {
  name: 'new-medical-modal',

  components: {
    vSelect
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    actionButtonText: {
      String, default: 'Deletar'
    },
    actionButtonColor: {
      String, default: 'danger'
    }
  },

  computed: {
    cacs () {
      return this.$store.getters['cac/forSelect']
    },
    states () {
      return this.$store.getters['states/getStates']
    },
    validateForm () {
      return !this.errors.any()
    }
  },

  data () {
    return {
      localShow: false,
      doctor: {
        name: '',
        crm: '',
        class: 'Geral',
        observation: null,
        cacs: [],

        phones: [
          {type: 'mobile', phone: null},
          {type: 'phone', phone: null},
          {type: 'other', phone: null}
        ],

        address: {
          logradouro: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          zip_code: null,
          observation: null,
          type_address: 'res',
          state_id: null,
          city_id: null,
          country: 1 // Brasil
        }
      },

      countryOptions: [{ label: 'Brasil', value: 1 }],

      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ],

      cityOptions:[]
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.doctor = {
          name: '',
          crm: '',
          class: 'Geral',
          observation: null,
          cacs: [],

          phones: [
            {type: 'mobile', phone: null},
            {type: 'phone', phone: null},
            {type: 'other', phone: null}
          ],

          address: {
            logradouro: null,
            street: null,
            number: null,
            complement: null,
            neighborhood: null,
            zip_code: null,
            observation: null,
            type_address: 'res',
            state_id: null,
            city_id: null,
            country: 1 // Brasil
          }
        }
        this.$emit('cancel')
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('doctor/store', this.doctor)
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async searchZipCode ($event) {
      const zip_code = this.doctor.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        this.$http._noHeaders = true //enviando um get sem Autorization no header
        const resp = await this.$http.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.doctor.address.neighborhood = resp.data.bairro
        this.doctor.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.doctor.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.doctor.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.doctor.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.doctor.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },

    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          // this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find(x => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find(x => x.label === city)
            }
            this.doctor.address.city_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    }
  },
  created () {
    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')

    if (!moduleCac.isRegistered) {
      this.$store.registerModule('cac', moduleCac)
      moduleCac.isRegistered = true
    }
    this.$store.dispatch('cac/fetchAll')
    //ESTADO DEFAULT SC
    this.doctor.address.state_id = 24
    this.fillCity(this.doctor.address.state_id)
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
